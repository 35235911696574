import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import styled from "styled-components";
import media from "styled-media-query";
import loadable from '@loadable/component'

import Layout from "../../components/Layout";
import NewsRoll from "../../components/NewsRoll";

import MainImage1 from "../../img/top-2.jpg";
import BgImage1 from "../../img/news-bg.png";

const GoogleCalendar = loadable(() => import('../../components/Calendar'))

const NewsIndexPage = ({ pageContext }) => {
  const { breadcrumb: { crumbs }} = pageContext;

  return (
    <Layout>
      {/*
      パンくず
      メインビジュアル
      タイトル
      NewsRoll
      ページャー
      パンくず
      */}
      <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="おしらせ・イベント"  />
      <ImageBig src={MainImage1}></ImageBig>
      <MidashiBg>
        <Midashi>おしらせ・イベント</Midashi>
      </MidashiBg>
      <GoogleCalendar></GoogleCalendar>
      <NewsRollDiv>
        <NewsRoll mini={false} />
      </NewsRollDiv>
      <br />
      {/*
      本当はページャーが入ります
      */}
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="おしらせ・イベント"  />
      </Body>
    </Layout>
  );
}

export default NewsIndexPage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  text-align: center;
`;

const MidashiBg = styled.div`
  width: 100%;
  background-image: url(${BgImage1});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 8em;
    margin: 0 0 2em 0;
    padding: 2em 15vw 0 15vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 15em;
    margin: 0 0 5em 0;
    padding: 5em 15vw 0 15vw;
  `}
`;

const NewsRollDiv = styled.div`
  margin: auto;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 70%;
  `}
`;

const ImageBig = styled.img`
  width: 100%;
  border-radius: 10px;
  margin: 0 0 3vh 0;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 80vh;
  `}
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 20pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 28pt;
  `}
`;
